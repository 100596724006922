import React, {FC, Fragment, useRef} from "react";
import AccordionLIst from './AccordionLIst'
import cls from "../builder.module.scss"
import Script from "next/script";
import {schemaString} from "../../schemaorg";

interface IProps {
    item: {
        customClassNames: string;
        id: number;
        title: string;
        description: string;
        accordionFaq: Array<{
            id: string;
            title: string;
            description: string;
        }>
        convertedSettings: {
            animationAos: { "data-aos": any },
            styles: any;
            parentStyles: any
        }
    }
}

const Accordion: FC<IProps> = (
    {
        item: {
            id,
            customClassNames,
            accordionFaq,
            convertedSettings: {
                animationAos: {"data-aos": dataAos = null} = {},
                styles = {},
                parentStyles = {}
            } = {}
        }
    }
) => {
    const schema = schemaString(accordionFaq)
    const faqsRef = useRef(null)

    return <Fragment>
        <Script
            id={`${id}`}
            type="application/ld+json"
            dangerouslySetInnerHTML={{__html: JSON.stringify(schema)}}
        />
        <div
            className={cls.faq_component}
            data-aos={dataAos}
            style={{
                ...parentStyles,
                margin: styles.margin,
                padding: styles.padding
            }}
        >
            <div style={{width: "100%"}}>
                <div className={cls.faqs} ref={faqsRef}>
                    {accordionFaq.map(faq => {
                        return <AccordionLIst
                            key={faq.id}
                            styles={styles}
                            faq={faq}
                            customClassNames={customClassNames}
                        />
                    })}
                </div>
            </div>
        </div>
    </Fragment>
};

export default Accordion