import {
    MENU_LIST,
    SET_SOCIAL,
    SET_DOMAIN,
    SET_POPUP,
    SET_FB_PIXEL,
    CATEGORIS_DATA,
    SET_TRANSLATIONS,
    SET_POPUP_NAME,
    SET_UP_CROSS_PRODS,
    SET_TEMPORARY_DATA,
    SET_DETAIL_URL,
    SET_WINDOW_SIZE,
    SET_BUILDER_IMAGE_URL,

} from "./generalActionTypes";

export function setDomain(payload: any) {
    return (dispatch: (arg0: { type: string; payload: any; }) => any) =>
        dispatch({
            type: SET_DOMAIN,
            payload,
        });
}

export function setTranslations(payload: any) {
    return (dispatch: (arg0: { type: string; payload: any; }) => any) =>
        dispatch({
            type: SET_TRANSLATIONS,
            payload,
        });
}

export function setMenuList(payload: any) {
    return (dispatch: (arg0: { type: string; payload: any; }) => any) =>
        dispatch({
            type: MENU_LIST,
            payload,
        });
}

export const setCrossValid = (payload: boolean) => ({
    type: "SET_VALID_CROSS",
    payload,
});


export const setUpCrossProd = (payload: boolean) => ({
    type: SET_UP_CROSS_PRODS,
    payload,
});

export const setTempData = (payload: any[]) => ({
    type: SET_TEMPORARY_DATA,
    payload,
});

export const setCategories = (payload: any) => ({
    type: CATEGORIS_DATA,
    payload,
});

export const setFbPixel = (payload: any) => ({
    type: SET_FB_PIXEL,
    payload,
});

export const setPopup = (payload: boolean) => ({
    type: SET_POPUP,
    payload,
});
export const setPopupName = (payload: string) => ({
    type: SET_POPUP_NAME,
    payload,
});

export const setSocial = (payload: any) => ({
    type: SET_SOCIAL,
    payload,
});

export const setDetailUrl = (payload: string) => ({
    type: SET_DETAIL_URL,
    payload,
});

export const setWindowSize = (payload: number) => ({
    type: SET_WINDOW_SIZE,
    payload,
});

export const setBuilderUrl = (payload: string) => ({
    type: SET_BUILDER_IMAGE_URL,
    payload,
});
