import React, {FC, Fragment, ReactNode} from 'react'
import cls from "../builder_carousel.module.scss";
import HoverImage from '../../HoverImage';

interface IProps {
    card: Record<string, string>,
}

const BrandListCard: FC<IProps> = ({card}) => {
    const {
        image_path = "",
        linkValue = "",
        targeting = "",
        hover_path = ""
    } = card
    const BrandListChild = ({children}: {children: ReactNode}) => {
        if (linkValue) return <a href={linkValue} target={targeting} rel="noreferrer" children={children} />
        return <Fragment>{children}</Fragment>
    }

    return <div className={cls.carousel_card_wrapper}>
        <BrandListChild children={<div className={cls.carousel_card}>
            <div className={cls.carousel_card_image_wrapper}>
                <img
                    alt="carousel card image"
                    aria-hidden="true"
                    src={image_path}
                />
                <HoverImage
                    src={hover_path}
                    className={cls.carousel_card_hover}
                />
            </div>
        </div>}/>
    </div>
}

export default BrandListCard