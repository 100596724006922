import {
    CATEGORIS_DATA,
    MENU_LIST,
    SET_DETAIL_URL,
    SET_DOMAIN,
    SET_FB_PIXEL,
    SET_POPUP,
    SET_POPUP_NAME,
    SET_SOCIAL,
    SET_TEMPORARY_DATA,
    SET_TRANSLATIONS,
    SET_UP_CROSS_PRODS,
    SET_WINDOW_SIZE,
    SET_BUILDER_IMAGE_URL,
} from "./generalActionTypes";

const initialState = {
    menuList: [],
    Backorders: null,
    translations: {},
    popUp: false,
    popUpName: "",
    domain: "",
    fbPixel: false,
    initialMaxPrice: 0,
    initialMinPrice: 0,
    coreConfigs: {},
    upCrossProd: [],
    temporaryData: [],
    crossValid: false,
    dbName: "",
    metas: null,
    detailUrl: "",
    roomID: null,
    windowSize: 0,
    imgUrl: "",
};

export default function generalReducer(state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
        case "SET_VALID_CROSS":
            return {
                ...state,
                crossValid: action.payload,
            };

        case SET_TEMPORARY_DATA:
            return {
                ...state,
                temporaryData: action.payload,
            };
        case SET_UP_CROSS_PRODS:
            return {
                ...state,
                upCrossProd: action.payload,
            };
        case SET_DOMAIN:
            return {
                ...state,
                domain: action.payload,
            };
        case SET_TRANSLATIONS:
            return {
                ...state,
                translations: action.payload,
            };
        case MENU_LIST:
            return {
                ...state,
                menuList: action.payload,
            };
        case CATEGORIS_DATA:
            return {
                ...state,
                categories: action.payload,
            };
        case SET_FB_PIXEL:
            return {
                ...state,
                fbPixel: action.payload,
            };
        case SET_POPUP:
            return {
                ...state,
                popUp: action.payload,
            };
        case SET_POPUP_NAME:
            return {
                ...state,
                popUpName: action.payload,
            };
        case SET_SOCIAL:
            return {
                ...state,
                social: action.payload,
            };
        case SET_DETAIL_URL:
            return {
                ...state,
                detailUrl: action.payload,
            }
        case SET_WINDOW_SIZE:
            return {
                ...state,
                windowSize: action.payload,
            }
        case SET_BUILDER_IMAGE_URL:
            return {
                ...state,
                imgUrl: action.payload,
            }

        default:
            return state;
    }
}
