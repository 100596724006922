import React, {FC, useEffect, useRef, useState} from 'react'
import cls from "../builder.module.scss"

interface IProps {
    styles: any;
    faq: {
        title: string,
        id: string,
        description: string
    };
    customClassNames: string;
}

const AccordionLIst: FC<IProps> = (
    {
        faq: {title, description},
        styles: {backgroundColor, color},
        customClassNames
    }
) => {
    const [open, setOpen] = useState<boolean>(false)
    const itemRef = useRef<any>(null)
    const bodyRef = useRef<any>(null)

    const handleAccordion = () => {
        if (bodyRef.current) {
            if (bodyRef.current.style.maxHeight) {
                bodyRef.current.style.maxHeight = null;
                bodyRef.current.style.paddingBottom = 0;
            } else {
                bodyRef.current.style.maxHeight = (bodyRef.current.scrollHeight + 20) + "px";
                bodyRef.current.style.backgroundColor = "transparent";
                bodyRef.current.style.paddingBottom = "20px";
            }
        }
    }

    useEffect(() => {
        const handleEvent = (e: MouseEvent) => {
            if (itemRef && itemRef.current && !itemRef.current.contains(e.target)) {
                setTimeout(() => {
                    setOpen(false)

                    if (bodyRef.current.style.maxHeight) {
                        handleAccordion()
                    }
                }, 100)
            }
        }

        document.addEventListener("mousedown", handleEvent)

        return () => document.removeEventListener("mousedown", handleEvent)
    }, []);

    const handleOpen = () => {
        setOpen(!open)
        handleAccordion()
    }

    const plusClasses = {
        "true": `${cls.plus} ${cls.open}`,
        "false": `${cls.plus}`
    }

    return <div className={`${cls.faq_component_item} ${customClassNames}`} ref={itemRef}>
        <div
            className={cls.faq_title_wrapper}
            style={{backgroundColor: backgroundColor}}
            onClick={handleOpen}
        >
            <div className={cls.faq_title}>
                {title}
            </div>
            <span></span>
            <span className={plusClasses[`${open}`]}></span>
        </div>
        <div
            className={cls.faq_body}
            ref={bodyRef}
            style={{color: color}}
            dangerouslySetInnerHTML={{__html: description}}
        />
    </div>
};

export default AccordionLIst