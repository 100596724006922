import {
    MOBILE_MENU_CLOSE,
    MOBILE_MENU_OPEN,
    MOBILE_SEARCH_CLOSE,
    MOBILE_SEARCH_OPEN,
} from './mobileMenuActionTypes';

export function mobileMenuOpen() {
    return {type: MOBILE_MENU_OPEN};
}

export function mobileMenuClose() {
    return {type: MOBILE_MENU_CLOSE};
}

export function mobileSearchOpen() {
    return {type: MOBILE_SEARCH_OPEN};
}

export function mobileSearchClose() {
    return {type: MOBILE_SEARCH_CLOSE};
}
