import React, {FC} from "react";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

interface IProps {
    item: {
        customClassNames: string;
        masonry: {
            images: Array<Record<string, string>>
            settings: {
                animation: string,
                desktopColumns: number,
                tabletColumns: number,
                mobileColumns: number,
                gap: number
            }
        }
    }
}

const MasonryComponent: FC<IProps> = ({item}) => {
    const {
        customClassNames,
        masonry: {
            images = [{}],
            settings: {
                animation,
                desktopColumns,
                tabletColumns,
                mobileColumns,
                gap
            }
        }
    } = item

    return (
        <div data-aos={animation} className={customClassNames}>
            <ResponsiveMasonry
                columnsCountBreakPoints={{
                    425: mobileColumns,
                    600: tabletColumns,
                    991: desktopColumns
                }}
            >
                <Masonry gutter={`${gap}px`}>
                    {images.map(({id, image_path}) => (
                        <img
                            key={id}
                            style={{height: "unset !important"}}
                            alt="masonry image"
                            aria-hidden="true"
                            src={image_path}
                        />
                    ))}
                </Masonry>
            </ResponsiveMasonry>
        </div>
    );
}

export default MasonryComponent