export const SET_POPUP = "SET_POPUP";
export const MENU_LIST = "MENU_LIST";
export const SET_DOMAIN = "SET_DOMAIN";
export const SET_SOCIAL = "SET_SOCIAL";
export const SET_FB_PIXEL = "SET_FB_PIXEL";
export const SET_POPUP_NAME = "SET_POPUP_NAME";
export const CATEGORIS_DATA = "CATEGORIS_DATA";
export const SET_TRANSLATIONS = "SET_TRANSLATIONS";
export const SET_UP_CROSS_PRODS = "SET_UP_CROSS_PRODS";
export const SET_TEMPORARY_DATA = "SET_TEMPORARY_DATA";
export const SET_DETAIL_URL = "SET_DETAIL_URL";
export const SET_WINDOW_SIZE = "SET_WINDOW_SIZE";
export const SET_BUILDER_IMAGE_URL = "SET_BUILDER_IMAGE_URL"