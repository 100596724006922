export const url = {
    home: () => "/",

    catalog: () => "/catalog",

    category: (category) => {
        let stringQuery = `/catalog/${category.slug ? category.slug : category?.department?.slug || category.link.slug}`
        if (category && category.query && category.query.page) {
            return `${stringQuery}?page=1`;
        }
        if (category && category.query && category.query.currencies) {
            return `${stringQuery}?currencies=${category.query?.currencies}`;
        } else {
            return `${stringQuery}`;
        }
    },

    product: (product) => {
        if (product.flats && product?.flats.length > 0) {
            return `/${product.flats[0].url_key}`;
        } else {
            return `/${product.url_key}`;

        }


    },
}
const replacer = (string, filterName) => {
    const index = string.indexOf(filterName);
    if (index > -1) {
        const endIndex = string.includes('&') ? string.indexOf('&', index) + 1 : string.length;
        const substringToRemove = string.substring(index, endIndex);
        const newString = string.replace(substringToRemove, '');
        return replacer(newString, filterName); // Recursive call
    }
    return string;
};

export const rightUrl = (path, router) => {
    if (router.asPath.includes("?")) {
        let x = `${path}${router.asPath.slice(router.asPath.indexOf("?"))}`
        let y = replacer(clearQueryPath(x), "page");
        return y.indexOf("?") === y.length - 1 ? y.slice(0, y.length - 1) : y
    }
    return path
}

const clearQueryPath = (string) => {
    let ab;
    if (string.includes("filter_")) {
        ab = replacer(string, "filter")
        if (ab.includes("filter_")) {
            return clearQueryPath(ab)
        }
        return ab
    }
    return string
}

export const replaceDescr = (str) => {
    if (str) {
        let obj = {}
        let newStr = str
        while (newStr.includes("<table")) {
            let start = newStr.lastIndexOf("<table")
            let end = newStr.lastIndexOf("</table>") + 8

            obj[newStr.slice(start, end)] = [start, end]

            newStr = newStr.replace(newStr.slice(start, end), '')
        }
        for (let key in obj) {
            let start = obj[key][0]
            let end = obj[key][1]
            let value = `<div className="table_wrapper" style="overflow: scroll">${key}</div>`
            str = str.replace(str.slice(start, end), value)
        }
        return str
    }
}

export const spaceToUpperCaseConvertor = (input) => {
    return input
        ?.replace(/[^a-zA-Z]+/g, ' ')
        .split(' ').map((word, index) => {
            if (index === 0) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('');
}

export const idGen = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export const createBuilderData = (array, arrayElement) => {
    let data = array?.map((el) => {
        if (Object.keys(el)[0] === arrayElement) {
            return Object.values(el)[0]
        }
    })
    data = data?.filter(element => element !== undefined);
    return data[0]
}

export const isDefaultImage = (url) => {
    return url.split('/').includes('_next')
}

export const checkDetail = (details) => {
    if (details && details.length > 0) {
        return details.some(el => +el.attribute?.[0]?.is_visible_on_front === 1)
    } else return false
}

export const createRightDate = (date) => {
    let day = new Date(date);

    let dd = String(day.getDate()).padStart(2, "0");
    let mm = String(day.getMonth() + 1).padStart(2, "0");
    let yyyy = day.getFullYear();
    return dd + "/" + mm + "/" + yyyy
}

export const validate = (currentForm, hasState) => {

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let errors = {}
    for (const [key, value] of Object.entries(currentForm)) {
        if (key !== "additional" && key !== "company_name" &&
            (
                (Array.isArray(value) && !Boolean(value[0])) ||
                (!Array.isArray(value) && typeof value !== "boolean" && !Boolean(value)) ||
                (key === "state" && hasState && value === "no state")
            )) {
            errors[`${key}_error`] = "this field is required"
        }

        if (key === "email" && !regex.test(value)) {
            errors[`${key}_error`] = "invalid email format"
        }
    }
    return errors
}

export const generateTranslateKey = (str = "") => {
    return str.split(" ").map(el => el[0]?.toUpperCase() + el.slice(1))?.join("")
}
