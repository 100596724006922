declare global {
    interface Window {
        gtagInitialized?: boolean;
        fbq: (...args: any[]) => void;
    }
}

export const loadGtag = (GTAG_ID: string) => {
    if (!window.gtagInitialized && GTAG_ID) {
        window.gtagInitialized = true;

        const script = document.createElement("script");
        script.src = `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`;
        script.defer = true;
        script.async = true;
        script.setAttribute("data-important", "false")
        document.head.appendChild(script);

        const inlineScript = document.createElement("script");
        inlineScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GTAG_ID}', { page_path: window.location.pathname, debug_mode: false });
        `;
        document.head.appendChild(inlineScript);
    }
};

export const handleRouteChange = (url: string, GTAG_ID: string) => {
    if ('gtag' in window && typeof window.gtag === 'function') {
        window.gtag("config", GTAG_ID, { page_path: url });
    }
};