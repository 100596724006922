import React, {FC, Fragment} from "react";
import Slider from "react-slick";
import cls from "../blocks/BlockSlideShow.module.scss"
import {domainUrl} from "../../helper"
import {TranslationsType} from "../../types/homePageTypes";

interface ISliders {
    path: string;
    title: string;
    content: string | TrustedHTML;
    settings: Record<`${number}`, { priority?: boolean; loading?: "eager" | "lazy" }>;
    slider_path: string;
}

const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
};

interface IProps {
    slider: {sliders: Array<ISliders>},
    dbName: string,
    translationsData: TranslationsType;
}

const SliderComponent: FC<IProps> = (
    {
        slider: {sliders},
        dbName,
        translationsData
    }
) => {
    return (
        <Fragment>
            <div className={cls.home_slide_container}>
                <Slider {...settings}>
                    {sliders.map((
                        {
                            path: realPath,
                            title: altTitle,
                            content: dangerouslyContent,
                            settings: settingForImg,
                            slider_path
                        }, index: number) => {
                        const buttonHandler = {
                            true: <div className={cls["block-slideshow__slide-button"]}>
                                <button
                                    onClick={() => window.open(slider_path, "_blank")}
                                    className={`btn btn-orange ${cls["slideshow-btn"]}`}
                                >
                                    {translationsData.SliderButtonTitle}
                                </button>
                            </div>,
                            false: null
                        }
                        return (
                            <div key={index} className={cls["block-slideshow__slide"]}>
                                <div className={cls.slider_image_wrapper}>
                                    <img
                                        src={domainUrl(`${dbName}/storage/${realPath}`)}
                                        alt={altTitle}
                                        {...settingForImg}
                                    />
                                </div>
                                <div className={cls["block-slideshow__slide-content"]}>
                                    <div
                                        className={cls["block-slideshow__slide-title"]}
                                        dangerouslySetInnerHTML={{__html: dangerouslyContent}}
                                    />
                                </div>
                                {buttonHandler[`${!!slider_path}`]}
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </Fragment>
    );
};

export default SliderComponent;
