import React, { FC } from "react";
import cls from "./customHeader.module.scss";
import {ArrowRoundedDown9x6Svg} from "../../../../svg";
import Link from "next/link";
import { IMenu } from "../../../../types/productPageTypes";

interface IProps {
    items: Array<IMenu>;
    openMenus: number[];
    toggleSub: (id: number) => void;
}

const CustomItemList: FC<IProps> = ({ items, toggleSub, openMenus }) => {
    return (
        <ul className={`${cls.menu__submenu}`}>
            {items.map((eachMenu, index) => {
                const { name = "", id, children = [], url_key = "", target } = eachMenu;
                const existThisMenuId = openMenus.some(subId => subId === id)
                let arrow = null;
                let submenu = null;
                if (children.length) {
                    arrow = <div className={`${cls.wrapper__bttn} ${existThisMenuId && cls.closed}`}>
                        <ArrowRoundedDown9x6Svg className={cls.menu__arrow}/>
                    </div>
                    submenu = <div className={`${cls.deep__submenu} ${existThisMenuId && cls.opened__drop}`}>
                        <CustomItemList items={children} toggleSub={toggleSub} openMenus={openMenus} />
                    </div>
                }

                return (
                    <li className={cls.menu__li} key={index}>
                        <div className={cls.menu_item__container} onClick={e => {
                            toggleSub(id as number);
                            e.stopPropagation()
                        }}>
                            <button className={`${cls.link__button} ${!!children.length && cls.only__link}`} >
                                <Link href={url_key} prefetch={true}>
                                    <a
                                        className={cls.menu__li__a}
                                        {...target}
                                    >
                                        {name}
                                    </a>
                                </Link>
                            </button>
                            {arrow}
                        </div>
                        {submenu}
                    </li>
                );
            })}
        </ul>
    );
};

export default CustomItemList;
