import { GET_PAYMENT_CURRENCY } from "./currencyActionTypes";

const initialState = {
    paymentMethod: ""
};

export default function currencyReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PAYMENT_CURRENCY:
            return {
                ...state,
                paymentMethod: action.payload
            }
        default:
            return state
    }
}
