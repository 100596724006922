import React, {FC, Fragment, useState} from "react";
import {IProduct} from "../../../types/homePageTypes";
import {addToCartEventPixel} from "../../shared/ProductCard/helpers";
import {useDispatch, useSelector} from "react-redux";
import {useRouter} from "next/router";
import {IState} from "../../../types/state";
import {setCrossValid, setPopupName, setTempData} from "../../../store/general";
import {cartAddItem, getUpOrCrossProd} from "../../../store/cart";
import {ICurrencies} from "../../ForCWV/Wrappers/MainLayout";
import shopApi from "../../../api/shop";

interface IProps {
    item: {
        customClassNames: string;
        buttonType: string;
        buttonValue: string;
        icon: string;
        iconPosition: string;
        settings: any;
        targeting: "_blank" | "";
        linkValue: string;
        productForBuyNow: IProduct;
        hoverSettings: {};
        convertedSettings: {
            animationAos: { "data-aos": any },
            styles: any;
            parentStyles: any
        }
    },
    selectedLocale: string,
    backOrderValue: string,
    currencies: Array<ICurrencies>;
}

const Button: FC<IProps> = (
    {
        item: {
            buttonType,
            buttonValue = "",
            icon,
            iconPosition = "first",
            linkValue,
            targeting,
            customClassNames,
            productForBuyNow,
            hoverSettings,
            convertedSettings: {
                animationAos: {"data-aos": dataAos = null} = {},
                styles = {},
                parentStyles = {}
            } = {}
        },
        selectedLocale = "",
        backOrderValue = "",
        currencies: [{
            code: selectedCurrCode = "",
            symbol: selectedCurrSymbol = "",
            exchange_rate: {rate: selectedCurrRate = "1"} = {}
        } = {}] = []
    }
) => {
    const {
        qty: [productQuantity = 0] = [],
        id: productId = 0,
        flats: [{
            min_qty = 1,
            has_up_sell = 0,
            has_cross_sell = 0,
            url_key = ""
        } = {}] = [],
        type: productType = ""
    } = productForBuyNow || {}

    const [isHovered, setIsHovered] = useState<boolean>(false);
    const cartToken = useSelector((state: IState) => state.cartToken);
    const customer = useSelector((state: IState) => state.customer);

    const stylesFromBuilder = isHovered
        ? {...styles, ...hoverSettings}
        : styles

    const linkStyles: React.CSSProperties = {
        ...stylesFromBuilder,
        display: "flex",
        fontSize: "16px",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
        gap: icon && buttonValue ? "5px" : "0",
        width: styles.width === "unset" ? "max-content" : styles.width,
        flexDirection: iconPosition === "second" ? "row-reverse" : "row"
    }

    const buyNowStyles: React.CSSProperties = {
        ...stylesFromBuilder,
        display: "flex",
        fontSize: "16px",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
        gap: icon && buttonValue ? "5px" : "0",
        flexDirection: iconPosition === "second" ? "row-reverse" : "row"
    }

    const dispatch = useDispatch()
    const {push: routerPush} = useRouter();

    const isProductInStock = productQuantity > 0 || !!parseFloat(backOrderValue)

    const cartDataObj = {
        product: productForBuyNow,
        quantity: 1,
        cartToken,
        customer: customer as { token: string },
        selectedLocale,
        selectedCurrCode,
        optionsObject: null,
        addToast: () => {
        }
    }

    const upCrossSellHandle: Record<string, Record<string, () => void>> = {
        "true": {
            "true": () => {
                dispatch(setPopupName("upsell"));
                dispatch(getUpOrCrossProd(
                    productId,
                    'up-sell',
                    selectedLocale,
                    {exchange_rate: {rate: selectedCurrRate}, symbol: selectedCurrSymbol, code: selectedCurrCode}
                ))
                dispatch(setTempData([productForBuyNow]))
            },
            "false": () => upCrossSellHandle["true"]["true"](),
        },
        "false": {
            "true": () => {
                dispatch(getUpOrCrossProd(
                    productId,
                    'cross-sell',
                    selectedLocale,
                    {exchange_rate: {rate: selectedCurrRate}, symbol: selectedCurrSymbol, code: selectedCurrCode}
                ))
                dispatch(setCrossValid(true));
                dispatch(setPopupName("crossSell"));
                dispatch(setTempData([productForBuyNow]))
            },
            "false": () => {
            }
        }
    }

    const openUpCrossProd = () => {
        return upCrossSellHandle[`${!!parseFloat(has_up_sell)}`][`${!!parseFloat(has_cross_sell)}`]()
    }

    const handleBuyNow = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        shopApi.getEmptyCart(
            customer.authenticated,
            customer.token,
            cartToken.cartToken
        ).then(async res => {
            if (res.message) {
                await (async () => {
                    const addItemCallback = () => cartAddItem.apply(null, Object.values(cartDataObj) as any)
                    if (isProductInStock && min_qty <= 1 && productType !== "downloadable") {
                        if (has_up_sell) {
                            openUpCrossProd();
                            return;
                        } else if (has_cross_sell) {
                            openUpCrossProd();
                            addToCartEventPixel(productForBuyNow, 1, {code: selectedCurrCode} as ICurrencies)
                            dispatch(addItemCallback());
                            await routerPush("/checkout")
                            return;
                        }
                        addToCartEventPixel(productForBuyNow, 1, {code: selectedCurrCode} as ICurrencies)
                        dispatch(addItemCallback());
                        await routerPush("/checkout")
                        return;
                    }
                    return await routerPush(`/${url_key}`)
                })()
            }
        })
    }

    const ButtonTypeChecker = () => {
        const IconGlobal = () => {
            if (!icon) return null;
            return <div className="builder-button" dangerouslySetInnerHTML={{__html: icon}}/>
        }
        const TextInside = () => {
            if (!linkValue && buttonType !== "Buy now") return <span>{buttonValue}</span>
            return <>{buttonValue}</>
        }
        const CompareInfo = (
            <Fragment>
                <IconGlobal />
                <TextInside />
            </Fragment>
        )
        const commonProps = {
            className: customClassNames,
            children: CompareInfo,
        };

        if (buttonType === "Buy now") {
            return <button
                {...commonProps}
                style={buyNowStyles}
                onClick={handleBuyNow}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />
        } else if (linkValue) {
            return <a
                {...commonProps}
                style={linkStyles}
                href={linkValue}
                rel={"noreferrer"}
                target={targeting}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            />
        }
        return <button
            {...commonProps}
            style={linkStyles}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        />
    }

    return (
        <div data-aos={dataAos} style={parentStyles}>
            <ButtonTypeChecker />
        </div>
    );
}

export default Button;